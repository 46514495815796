<template>
    <div class="page iphone-x-adaptation">
        <div class="adress">
            <div class="kfc-name">{{ mealStoreInfo.name }}</div>
            <div class="adress-detail">{{ mealStoreInfo.adress }}</div>
        </div>

        <div class="order-detail box-shadow">
            <div class="name">餐品详情</div>
            <div class="list">
                <template v-for="(item, index) in productList">
                    <div
                        :key="index"
                        class="order-single"
                        :class="[
                            { favour: 5 == item.activityType },
                            { voucher: 10 == item.activityType },
                        ]"
                    >
                        <img :src="item.productImageUrl" class="img" />
                        <div class="title">
                            <p class="p">{{ item.productName }}</p>
                        </div>
                        <div class="price">{{ item.salePrice }}</div>
                        <span class="original-price">¥{{ item.marketPrice }}</span>
                        <span class="num">x{{ item.quantity }}</span>
                    </div>
                </template>
            </div>
        </div>
        <div class="user-mobile-line box-shadow" v-if="!addressId">
            <div class="head">联系号码</div>
            <input
                class="user-moblie"
                type="text"
                v-model="userMobile"
                placeholder="请输入联系人手机号"
                maxlength="11"
                @change="$util.setTempData('create4agiso', 'userMobile', userMobile)"
            />
        </div>
        <div class="meal-type box-shadow">
            <div class="head">就餐方式</div>
            <div class="block">
                <div class="btn store-in" :class="{ cur: mealType == 1 }" @click="chooseType(1)">
                    <img class="img" src="@/assets/icons/icon-store-in.png" />
                    <span class="text">店内就餐</span>
                    <img class="select" src="@/assets/icons/icon-order-chose.png" />
                </div>
                <div class="btn store-out" :class="{ cur: mealType == 2 }" @click="chooseType(2)">
                    <img class="img" src="@/assets/icons/icon-store-out.png" />
                    <span class="text">打包带走</span>
                    <img class="select" src="@/assets/icons/icon-order-chose.png" />
                </div>
            </div>
        </div>
        <div class="user-remark box-shadow" @click="userRemarkDialogStatus = true">
            <div class="head">备注</div>
            <div class="value">{{ userRemark || '口味、偏好等要求' }}</div>
            <div class="right"></div>
        </div>
        <div class="padding-bottom"></div>

        <div class="cart-box iphone-x-adaptation open-cart">
            <div class="cart-line">
                <div class="submit-btn" @click="addCommit">
                    <div class="title">提交订单</div>
                    <div class="tip">Checkout</div>
                </div>
            </div>
        </div>
        <div
            class="user-remark-dialog"
            @click="userRemarkDialogStatus = false"
            v-if="userRemarkDialogStatus"
        >
            <div class="content iphone-x-adaptation" @click.stop>
                <div class="title">备注</div>
                <div class="close" @click="userRemarkDialogStatus = false"></div>
                <div class="textarea-box">
                    <textarea
                        class="textarea"
                        placeholder="请输入口味、偏好等要求，不支持额外代购其他物品哦～"
                        rows="3"
                        maxlength="50"
                        v-model="tempUserRemark"
                    ></textarea>
                    <div class="count">{{ tempUserRemark.length }}/50</div>
                </div>
                <div class="quick-box">
                    <div class="quick-box-title">快捷标签</div>
                    <div class="quick-list">
                        <div class="quick-item" @click="addUserRemark('去冰')">去冰</div>
                        <div class="quick-item" @click="addUserRemark('少冰')">少冰</div>
                        <div class="quick-item" @click="addUserRemark('老北京不加葱')">
                            老北京不加葱
                        </div>
                        <div class="quick-item" @click="addUserRemark('汉堡不加酱')">
                            汉堡不加酱
                        </div>
                    </div>
                </div>
                <div class="btn-save" @click="saveUserRemark">保存</div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    GET_STOP_INFO,
    GET_LAST_ORDER_MOBILE,
    AGISO_IDLE_BY_KFC_ORDER,
    AGISO_TB_BY_KFC_ORDER,
    GET_ORDER_NO_BY_AGISO,
    GET_ORDER_NO_BY_AGISO_TB,
    GET_PRODUCT_BY_ID,
} from 'api';
import { PAGE_ORDER_INFO } from 'page';
const mobileReg = /^1[3-9]{1}[0-9]{9}$/;
export default {
    data() {
        return {
            pageType: 0,
            addressId: 0,
            mealType: 1, //类型
            mealStoreInfo: {
                name: '',
                adress: '',
            },
            storeCode: '', //商店编号
            agisoIdleOrderNo: '',
            userMobile: '',
            userRemarkDialogStatus: false,
            userRemark: '',
            tempUserRemark: '',
            productList: [],
        };
    },
    components: {},
    computed: {},
    created() {
        this.pageType = this.$route.query.type || 0;
        this.storeCode = this.$route.query.storeCode || 0;
        this.addressId = this.$route.query.addressId || 0;
        this.agisoIdleOrderNo = this.$route.query.agisoIdleOrderNo || 0;

        if (this.$util.getTempData('create4agiso', 'mealType')) {
            this.mealType = this.$util.getTempData('create4agiso', 'mealType');
        }
        if (this.$util.getTempData('create4agiso', 'userMobile')) {
            this.userMobile = this.$util.getTempData('create4agiso', 'userMobile');
        }
        if (this.$util.getTempData('create4agiso', 'userRemark')) {
            this.userRemark = this.$util.getTempData('create4agiso', 'userRemark');
        }
        this.getUserMobile();
        this.getData();
        this.getProductList();
    },
    methods: {
        // 获取用户手机号
        getUserMobile() {
            this.$util.get(GET_LAST_ORDER_MOBILE).then(res => {
                if (res.success) {
                    if (!this.userMobile) {
                        this.userMobile = res.data;
                    }
                }
            });
        },
        // 获取店铺信息
        getData() {
            this.$util
                .post(GET_STOP_INFO, {
                    storeCode: this.storeCode,
                })
                .then(res => {
                    if (res.success) {
                        this.mealStoreInfo.name = res.data.storeName;
                        this.mealStoreInfo.adress = res.data.address;
                    }
                });
        },
        // 获取商品详情
        async getProductList() {
            let requestUrl = '';
            let requestData = {};
            if (this.pageType == 2) {
                requestUrl = GET_ORDER_NO_BY_AGISO;
                requestData = {
                    agisoIdleOrderNo: this.$route.query.agisoIdleOrderNo,
                };
            } else if (this.pageType == 3) {
                requestUrl = GET_ORDER_NO_BY_AGISO_TB;
                requestData = {
                    agisoTbOrderNo: this.$route.query.agisoTbOrderNo,
                };
            }
            let orderRes = await this.$util.get(requestUrl, requestData);
            if (!orderRes.success) {
                return;
            }
            let productRes = await this.$util.get(GET_PRODUCT_BY_ID, {
                productId: orderRes.data.qzProductId,
            });
            if (!productRes.success) {
                return;
            }
            productRes.data.quantity = orderRes.data.buyAmount || orderRes.data.buyNum;
            this.productList = [productRes.data];
        },
        // 去支付
        addCommit() {
            if (!mobileReg.test(this.userMobile)) {
                this.$toast('请输入正确的手机号码');
                return;
            }
            this.debounceSubmitOrder();
        },
        // 切换就餐方式
        chooseType(type) {
            this.mealType = type;
            this.$util.setTempData('create4agiso', 'mealType', type);
        },
        debounceSubmitOrder() {
            if(this.pageType == 2){
                this.$util.debounce(this.submitOrder4agiso)();
            }
            else if(this.pageType == 3){
                this.$util.debounce(this.submitOrder4agisoTb)();
            }
            
        },
        // 创建阿奇索订单
        submitOrder4agiso() {
            this.$util.eventStatistics({
                title: 'CreateOrder4Agiso',
            });
            let requestData = {
                agisoIdleOrderNo: this.agisoIdleOrderNo,
                eatType: this.mealType,
                mobile: this.userMobile,
                storeCode: this.storeCode,
                userRemark: this.userRemark,
            };
            this.$util.post(AGISO_IDLE_BY_KFC_ORDER, requestData).then(res => {
                if (res.success) {
                    this.$util.clearTempData('create4agiso');
                    this.$util.pageJump({
                        path: PAGE_ORDER_INFO,
                        query: {
                            orderNo: res.data.orderNo,
                            orderType: res.data.orderType,
                        },
                    });
                }
            });
        },
        // 创建阿奇索订单
        submitOrder4agisoTb() {
            this.$util.eventStatistics({
                title: 'CreateOrder4AgisoTb',
            });
            let requestData = {
                agisoTbOrderNo: this.$route.query.agisoTbOrderNo,
                eatType: this.mealType,
                mobile: this.userMobile,
                storeCode: this.storeCode,
                userRemark: this.userRemark,
            };
            this.$util.post(AGISO_TB_BY_KFC_ORDER, requestData).then(res => {
                if (res.success) {
                    this.$util.clearTempData('create4agisoTb');
                    this.$util.pageJump({
                        path: PAGE_ORDER_INFO,
                        query: {
                            orderNo: res.data.orderNo,
                            orderType: res.data.orderType,
                        },
                    });
                }
            });
        },
        // 保存备注
        saveUserRemark() {
            this.userRemark = this.tempUserRemark;
            this.userRemarkDialogStatus = false;
            this.$util.setTempData('create4agiso', 'userRemark', this.tempUserRemark);
        },
        // 添加备注
        addUserRemark(text) {
            this.tempUserRemark += text;
            this.tempUserRemark = this.tempUserRemark.substring(0, 50);
        },
    },
};
</script>
<style lang="less" scoped>
@import url('~@/lib/base.less');
@footHeight: .pxToRem(148) [];

.adress {
    width: 100%;
    background: white;
    border-radius: 0 0 .pxToRem(40) [] .pxToRem(40) [];
    margin-bottom: .pxToRem(10) [];
    .kfc-name {
        text-align: center;
        line-height: .pxToRem(40) [];
        font-size: .pxToRem(40) [];
        color: rgba(0, 0, 0, 0.9);
        padding: .pxToRem(30) [] .pxToRem(80) [] .pxToRem(62) [] .pxToRem(80) [];
        font-weight: 600;
        box-sizing: border-box;
        &:after {
            content: ' ';
            .base-center-x;
            bottom: .pxToRem(10) [];
            .sq(32);
            background: url('~@/assets/icons/icon-location-map.png');
            background-size: cover;
        }
    }
    .adress-detail {
        text-align: center;
        line-height: .pxToRem(40) [];
        font-size: .pxToRem(26) [];
        color: rgba(0, 0, 0, 0.5);
        padding: 0 .pxToRem(80) [] .pxToRem(32) [] .pxToRem(80) [];
    }
    .tips {
        .base-center-x;
        bottom: .pxToRem(-65) [];
        width: 100%;
        text-align: center;
        height: .pxToRem(40) [];
        line-height: .pxToRem(40) [];
        font-size: .pxToRem(26) [];
        color: @theme_color_first;
    }
}

.order-detail {
    width: .pxToRem(710) [];
    margin: 0 auto;
    margin-top: .pxToRem(20) [];
    border-radius: .pxToRem(30) [];
    background: white;
    .name {
        padding-left: .pxToRem(30) [];
        height: .pxToRem(88) [];
        line-height: .pxToRem(88) [];
        text-align: left;
        font-size: .pxToRem(30) [];
        font-weight: 600;
        color: rgba(0, 0, 0, 0.9);
    }
    .list {
        border-top: .pxToRem(1) [] solid rgba(0, 0, 0, 0.15);
        overflow: hidden;
        .order-single {
            display: flex;
            height: .pxToRem(140) [];
            line-height: .pxToRem(140) [];
            padding-left: .pxToRem(30) [];
            padding-right: .pxToRem(30) [];
            .img {
                .base-center-y;
                left: .pxToRem(30) [];
                max-width: .pxToRem(110) [];
                max-height: .pxToRem(80) [];
            }
            .title {
                .base-center-y;
                left: .pxToRem(160) [];
                right: .pxToRem(220) [];
                line-height: .pxToRem(42) [];
                font-weight: 600;
                .overflow(2);
            }
            .num {
                .base-center-y;
                left: .pxToRem(524) [];
                font-size: .pxToRem(26) [];
                height: .pxToRem(40) [];
                line-height: .pxToRem(40) [];
                color: rgba(0, 0, 0, 0.5);
            }
            .price {
                position: absolute;
                top: .pxToRem(30) [];
                right: .pxToRem(30) [];
                height: .pxToRem(40) [];
                line-height: .pxToRem(40) [];
                font-size: .pxToRem(30) [];
                font-weight: 600;
                &::before {
                    content: '¥';
                    position: absolute;
                    bottom: .pxToRem(1) [];
                    left: .pxToRem(-20) [];
                    font-weight: 400;
                    font-size: .pxToRem(24) [];
                    height: .pxToRem(36) [];
                    line-height: .pxToRem(36) [];
                    width: .pxToRem(24) [];
                    text-align: center;
                    color: rgba(0, 0, 0, 0.9);
                }
            }
            .original-price {
                position: absolute;
                bottom: .pxToRem(40) [];
                right: .pxToRem(30) [];
                height: .pxToRem(36) [];
                line-height: .pxToRem(36) [];
                font-size: .pxToRem(22) [];
                color: rgba(0, 0, 0, 0.3);
                text-decoration: line-through;
                .first-activity {
                    .base-center-y;
                    left: .pxToRem(-16) [];
                    white-space: nowrap;
                    transform: translate(-100%, -50%);
                }
            }
            &.has-sku {
                height: auto;
                .title {
                    top: .pxToRem(48) [];
                    .overflow(1);
                }
                .sku-box {
                    float: left;
                    margin-top: .pxToRem(72) [];
                    margin-bottom: .pxToRem(32) [];
                    margin-left: .pxToRem(130) [];
                    height: .pxToRem(36) [];
                    line-height: .pxToRem(36) [];
                    overflow: hidden;
                    background: rgba(0, 0, 0, 0.1);
                    max-width: .pxToRem(340) [];
                    .sku-item {
                        box-sizing: border-box;
                        padding: 0 .pxToRem(10) [];
                        font-size: .pxToRem(22) [];
                        .overflow(1);
                        display: flex;
                        .sku-product-name {
                            .overflow(1);
                        }
                        .sku-product-count {
                            flex: 1;
                            white-space: nowrap;
                        }
                    }
                    &.open {
                        height: auto;
                        overflow: unset;
                    }
                }
            }
            &.favour {
                .title {
                    p {
                        text-indent: .pxToRem(50) [];
                    }
                    &:before {
                        content: '惠';
                        text-indent: .pxToRem(0) [];
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: #d90209;
                        height: .pxToRem(40) [];
                        line-height: .pxToRem(40) [];
                        width: .pxToRem(40) [];
                        text-align: center;
                        border-radius: .pxToRem(4) [];
                        color: white;
                    }
                }
            }
            &.voucher {
                .title {
                    p {
                        text-indent: .pxToRem(50) [];
                    }
                    &:before {
                        content: '代';
                        text-indent: .pxToRem(0) [];
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: #d90209;
                        height: .pxToRem(40) [];
                        line-height: .pxToRem(40) [];
                        width: .pxToRem(40) [];
                        text-align: center;
                        border-radius: .pxToRem(4) [];
                        color: white;
                    }
                }
            }
        }
    }
    .coupon-line {
        width: 100%;
        height: .pxToRem(40) [];
        line-height: .pxToRem(40) [];
        border-top: .pxToRem(1) [] solid rgba(0, 0, 0, 0.15);
        padding: .pxToRem(24) [] 0;
        display: flex;
        justify-content: space-between;
        .title {
            padding-left: .pxToRem(90) [];
            font-size: .pxToRem(30) [];
            font-weight: 600;
            &::before {
                content: ' ';
                position: absolute;
                left: .pxToRem(30) [];
                top: 50%;
                transform: translateY(-50%);
                width: .pxToRem(44) [];
                height: .pxToRem(44) [];
                background: url('~@/assets/icons/icon-coupon.png');
                background-size: cover;
            }
        }
        .value {
            display: flex;
            padding-right: .pxToRem(16) [];
            align-items: center;
            .tip-use-coupon {
                height: .pxToRem(44) [];
                line-height: .pxToRem(44) [];
                font-size: .pxToRem(26) [];
                color: #865b22;
                background: #ffe5b5;
                padding: 0 .pxToRem(44) [] 0 .pxToRem(24) [];
                border-radius: .pxToRem(22) [];
                // &::before {
                //     content: '👉';
                //     position: absolute;
                //     left: .pxToRem(-12) [];
                //     transform: translateX(-100%);
                //     animation-name: finger;
                //     animation-duration: 0.3s;
                //     animation-iteration-count: infinite;
                // }
                @keyframes finger {
                    0% {
                        left: .pxToRem(-12) [];
                    }
                    50% {
                        left: .pxToRem(-24) [];
                    }
                    100% {
                        left: .pxToRem(-12) [];
                    }
                }
            }
            .tip-used-coupon {
                height: .pxToRem(44) [];
                line-height: .pxToRem(44) [];
                font-size: .pxToRem(30) [];
                color: #d80108;
                padding: 0 .pxToRem(44) [] 0 .pxToRem(24) [];
                border-radius: .pxToRem(22) [];
                font-weight: bold;
            }
            .tip-not-coupon {
                height: .pxToRem(44) [];
                line-height: .pxToRem(44) [];
                font-size: .pxToRem(26) [];
                color: rgb(153 153 153);
                background: rgb(244 244 244);
                padding: 0 .pxToRem(44) [] 0 .pxToRem(24) [];
                border-radius: .pxToRem(22) [];
            }
            .right {
                position: absolute;
                top: 50%;
                right: .pxToRem(24) [];
                transform: translateY(-50%) rotate(-45deg);
                width: .pxToRem(28) [];
                height: .pxToRem(28) [];
                &::before {
                    content: ' ';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    width: .pxToRem(15) [];
                    height: .pxToRem(3) [];
                    background: rgb(153, 153, 153);
                }
                &::after {
                    content: ' ';
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    width: .pxToRem(4) [];
                    height: .pxToRem(15) [];
                    background: rgb(153, 153, 153);
                }
            }
        }
    }
    .sum {
        border-top: .pxToRem(1) [] solid rgba(0, 0, 0, 0.15);
        line-height: .pxToRem(88) [];
        padding: .pxToRem(16) [] .pxToRem(30) [];
        text-align: right;
        box-sizing: border;
        .line {
            height: .pxToRem(56) [];
            line-height: .pxToRem(56) [];
            font-weight: 600;
            font-size: .pxToRem(26) [];
            display: flex;
            justify-content: flex-end;
        }
        .m-l-20 {
            margin-left: .pxToRem(20) [];
        }
        .num {
            color: @theme_color_first;
            font-size: .pxToRem(36) [];
            &::before {
                content: '¥';
                font-size: .pxToRem(24) [];
                width: .pxToRem(24) [];
                text-align: center;
            }
        }
        .integral {
            color: @theme_color_first;
            font-size: .pxToRem(36) [];
        }
    }
    &.special {
        margin-top: .pxToRem(10) [];
        .open-more-spcial {
            font-size: .pxToRem(26) [];
            height: .pxToRem(26) [];
            line-height: .pxToRem(26) [];
            padding: .pxToRem(30) [] 0;
            text-align: center;
            border-top: .pxToRem(2) [] solid #f2f2f2;
            span {
                padding-right: .pxToRem(34) [];
                &:after {
                    content: ' ';
                    position: absolute;
                    top: 50%;
                    right: 0;
                    margin-top: .pxToRem(-6.5) [];
                    background: url('~@/assets/img/spcial-more.png');
                    background-size: contain;
                    width: .pxToRem(24) [];
                    height: .pxToRem(13) [];
                }
            }
            &.open span::after {
                transform: rotate(180deg);
            }
        }
        .list {
            .order-single {
                height: .pxToRem(140) [];
                padding-left: .pxToRem(10) [];
                .reduced {
                    position: absolute;
                    bottom: .pxToRem(6) [];
                    right: .pxToRem(30) [];
                    color: #aba31b;
                    font-size: .pxToRem(20) [];
                    line-height: .pxToRem(26) [];
                    height: .pxToRem(26) [];
                }
                .icon-click {
                    .base-center-y;
                    right: .pxToRem(30) [];
                    height: .pxToRem(56) [];
                    .btn-reduce {
                        position: absolute;
                        top: 0;
                        left: 0;
                        .sq(56);
                        z-index: 1;
                    }
                    .quantity {
                        padding: 0 .pxToRem(74) [];
                        height: .pxToRem(56) [];
                        line-height: .pxToRem(56) [];
                    }
                    .btn-plus {
                        position: absolute;
                        top: 0;
                        right: 0;
                        .sq(56);
                        z-index: 1;
                        &.no-add {
                            opacity: 0.4;
                        }
                    }
                }
                .img {
                    .base-center-y;
                    max-width: .pxToRem(110) [];
                    max-height: .pxToRem(80) [];
                }
                .title {
                    font-weight: 400;
                }
                .price {
                    left: .pxToRem(160) [];
                    top: unset;
                    bottom: .pxToRem(30) [];
                    font-size: .pxToRem(30) [];
                    color: @theme_color_first;
                    font-size: .pxToRem(30) [];
                    width: max-content;
                    white-space: nowrap;
                    &::before {
                        display: inline-block;
                        position: relative;
                        left: unset;
                        bottom: unset;
                        color: @theme_color_first;
                        font-size: .pxToRem(30) [];
                        font-weight: 600;
                    }
                    .original-price {
                        position: absolute;
                        right: .pxToRem(-10) [];
                        top: .pxToRem(5) [];
                        white-space: nowrap;
                        transform: translateX(100%);
                        font-size: .pxToRem(22) [];
                        height: .pxToRem(36) [];
                        line-height: .pxToRem(36) [];
                    }
                }
            }
        }
    }
}

.box-shadow {
    box-shadow: .pxToRem(3) [] .pxToRem(3) [] .pxToRem(8) [] 0 rgba(0, 0, 0, 0.1);
}

.user-mobile-line {
    width: .pxToRem(710) [];
    margin: 0 auto;
    margin-top: .pxToRem(10) [];
    height: .pxToRem(90) [];
    border-radius: .pxToRem(30) [];
    padding-bottom: .pxToRem(10) [];
    background: white;
    display: flex;
    .head {
        height: .pxToRem(90) [];
        line-height: .pxToRem(90) [];
        font-size: .pxToRem(30) [];
        color: rgba(0, 0, 0, 0.9);
        font-weight: 600;
        padding-left: .pxToRem(30) [];
        width: .pxToRem(200) [];
        &:before {
            content: '仅用于接收取餐码，请正确填写';
            position: absolute;
            bottom: .pxToRem(0) [];
            left: .pxToRem(30) [];
            font-size: .pxToRem(22) [];
            line-height: .pxToRem(24) [];
            font-weight: 400;
            white-space: nowrap;
            color: rgba(0, 0, 0, 0.6);
        }
    }
    .user-moblie {
        flex: 1;
        text-align: right;
        background: rgba(0, 0, 0, 0);
        font-size: .pxToRem(28) [];
        margin-top: .pxToRem(5) [];
        padding-right: .pxToRem(30) [];
    }
}
.meal-type {
    width: .pxToRem(710) [];
    height: .pxToRem(200) [];
    background-color: #fff;
    margin: 0 auto;
    margin-top: .pxToRem(10) [];
    border-radius: .pxToRem(30) [];
    .head {
        height: .pxToRem(40) [];
        line-height: .pxToRem(40) [];
        padding-top: .pxToRem(24) [];
        padding-left: .pxToRem(30) [];
        font-size: .pxToRem(28) [];
        color: #363636;
        font-weight: bold;
    }
    .block {
        height: .pxToRem(64) [];
        padding: 0 .pxToRem(30) [];
        margin-top: .pxToRem(30) [];
        .jc-sb;
        .btn {
            width: .pxToRem(315) [];
            height: .pxToRem(64) [];
            box-sizing: border-box;
            border: .pxToRem(1) [] solid #313131;
            border-radius: .pxToRem(8) [];
            display: flex;
            justify-content: center;
            align-items: center;
            .img {
                .sq(44);
            }
            .text {
                height: .pxToRem(64) [];
                line-height: .pxToRem(64) [];
                font-size: .pxToRem(26) [];
                color: rgba(0, 0, 0, 0.5);
                margin-left: .pxToRem(20) [];
            }
            .select {
                display: none;
            }
            &.cur {
                border: .pxToRem(1) [] solid @theme_color_first;
                background: #fdf0f0;
                .select {
                    display: block;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    width: .pxToRem(39) [];
                    height: .pxToRem(26) [];
                }
                .text {
                    color: @theme_color_first;
                }
            }
        }
    }
}
.user-remark {
    width: .pxToRem(710) [];
    margin: 0 auto;
    margin-top: .pxToRem(10) [];
    height: .pxToRem(90) [];
    border-radius: .pxToRem(30) [];
    background: white;
    display: flex;
    .head {
        height: .pxToRem(90) [];
        line-height: .pxToRem(90) [];
        font-size: .pxToRem(30) [];
        color: rgba(0, 0, 0, 0.9);
        font-weight: 600;
        padding-left: .pxToRem(30) [];
        width: .pxToRem(200) [];
    }
    .value {
        flex: 1;
        height: .pxToRem(90) [];
        line-height: .pxToRem(90) [];
        text-align: right;
        font-size: .pxToRem(24) [];
        color: rgba(0, 0, 0, 0.9);
        padding-right: .pxToRem(56) [];
        .overflow(1);
    }
    .right {
        position: absolute;
        top: 49%;
        right: .pxToRem(24) [];
        transform: translateY(-50%) rotate(-45deg);
        width: .pxToRem(28) [];
        height: .pxToRem(28) [];
        &::before {
            content: ' ';
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: .pxToRem(15) [];
            height: .pxToRem(3) [];
            background: rgb(153, 153, 153);
        }
        &::after {
            content: ' ';
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: .pxToRem(4) [];
            height: .pxToRem(15) [];
            background: rgb(153, 153, 153);
        }
    }
}

.cart-box {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 200;
    .cart-line {
        height: .pxToRem(100) [];
        width: .pxToRem(702) [];
        margin: 0 auto .pxToRem(48) [];
        background: @theme_color_first;
        border-radius: .pxToRem(50) [];

        .submit-btn {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .title {
                text-align: center;
                line-height: .pxToRem(38) [];
                font-size: .pxToRem(36) [];
                color: white;
                font-weight: 600;
            }
            .tip {
                line-height: .pxToRem(26) [];
                font-size: .pxToRem(24) [];
                color: white;
                font-weight: 600;
                text-align: center;
            }
        }
    }
}

.user-remark-dialog {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 500;
    background: rgba(0, 0, 0, 0.65);
    .content {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: white;
        border-radius: .pxToRem(12) [] .pxToRem(12) [] 0 0;
        .title {
            width: 100%;
            height: .pxToRem(100) [];
            line-height: .pxToRem(100) [];
            text-align: left;
            padding-left: .pxToRem(24) [];
            font-size: .pxToRem(32) [];
            border-bottom: .pxToRem(1) [] solid rgba(0, 0, 0, 0.1);
        }
        .close {
            position: absolute;
            top: .pxToRem(24) [];
            right: .pxToRem(30) [];
            width: .pxToRem(50) [];
            height: .pxToRem(50) [];
            background: rgba(0, 0, 0, 0.1);
            border-radius: 100%;
            &:before {
                content: ' ';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(45deg);
                width: .pxToRem(2) [];
                height: .pxToRem(24) [];
                background: rgba(0, 0, 0, 0.9);
            }

            &:after {
                content: ' ';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(-45deg);
                width: .pxToRem(2) [];
                height: .pxToRem(24) [];
                background: rgba(0, 0, 0, 0.9);
            }
        }

        .textarea {
            display: block;
            width: .pxToRem(702) [];
            height: .pxToRem(160) [];
            line-height: .pxToRem(48) [];
            margin: 0 auto;
            border: none;
            background: rgba(0, 0, 0, 0.05);
            margin-top: .pxToRem(20) [];
            padding: .pxToRem(10) [];
            font-size: .pxToRem(26) [];
            box-sizing: border-box;
            border-radius: .pxToRem(8) [];
        }
        .count {
            position: absolute;
            bottom: .pxToRem(4) [];
            right: .pxToRem(40) [];
            font-size: .pxToRem(20) [];
            color: rgba(0, 0, 0, 0.6);
        }
        .quick-box {
            width: .pxToRem(702) [];
            margin: 0 auto;
            margin-top: .pxToRem(20) [];
            .quick-box-title {
                font-size: .pxToRem(24) [];
                color: rgba(0, 0, 0, 0.5);
            }
            .quick-list {
                margin-top: .pxToRem(12) [];
                .quick-item {
                    display: inline-block;
                    height: .pxToRem(48) [];
                    line-height: .pxToRem(48) [];
                    padding: 0 .pxToRem(12) [];
                    border: .pxToRem(1) [] solid rgba(0, 0, 0, 0.2);
                    margin-right: .pxToRem(16) [];
                    margin-bottom: .pxToRem(16) [];
                    border-radius: .pxToRem(8) [];
                }
            }
        }
        .btn-save {
            width: .pxToRem(702) [];
            text-align: center;
            margin: 0 auto;
            height: .pxToRem(80) [];
            line-height: .pxToRem(80) [];
            background: @theme_color_first;
            font-size: .pxToRem(28) [];
            color: white;
            border-radius: .pxToRem(16) [];
            margin-top: .pxToRem(40) [];
            margin-bottom: .pxToRem(20) [];
        }
    }
}

.padding-bottom {
    width: 100%;
    height: calc(@footHeight + .pxToRem(20) []);
}
</style>
<style lang="less" scoped>
@import url('~@/lib/base.less');
.show-original-price {
    .order-detail {
        .list .order-single {
            .title {
                right: .pxToRem(280) [];
            }
            .num {
                left: .pxToRem(464) [];
            }
        }
        .price {
            &:after {
                content: '到手价';
                position: absolute;
                top: 0;
                left: .pxToRem(-20) [];
                white-space: nowrap;
                transform: translateX(-100%);
                font-size: .pxToRem(24) [];
            }
        }
    }

    .fixed-pay {
        .btn {
            top: 0;
            bottom: 0;
            right: 0;
            border-radius: 0;
            width: unset;
            height: unset;
            line-height: unset;
            padding-left: .pxToRem(20) [];
            padding-right: .pxToRem(20) [];
            .title {
                margin-top: .pxToRem(8) [];
                margin-right: .pxToRem(8) [];
                font-size: .pxToRem(36) [];
                color: white;
                height: .pxToRem(44) [];
                line-height: .pxToRem(44) [];
            }
            .text {
                height: .pxToRem(32) [];
                line-height: .pxToRem(32) [];
                font-size: .pxToRem(24) [];
            }
        }
    }
}
</style>
